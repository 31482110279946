import React, { useCallback, useState, useEffect } from "react";
import { EyeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Image, Button, ListGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { URLs } from "routes";
import { getSingleTeam } from "services/teamService";
import { useSelector } from "react-redux";
import { socket } from "shared/socket";

const capitalizeFirstLetter = (string) =>
	string[0].toUpperCase() + string.slice(1);

const getInitialsOfFirstTwoWords = (text) => {
	if (!text) {
		return "";
	}
	const words = text.match(/\b[\w#*@\-_]+\b/g);
	if (words.length >= 2) {
		return words[0][0] + words[1][0];
	} else if (words.length === 1) {
		return words[0][0];
	} else {
		return "";
	}
};

const TeamFiveTeamMembers = ({ teamId }) => {
	const navigate = useNavigate();
	const [fiveMembers, setFiveMembers] = useState([]);
	const { user } = useSelector((state) => state.user);
	const [userData, setUserData] = useState(user);
	const [onlineUsers, setOnlineUsers] = useState([]);
	const [teamData, setTeamData] = useState(teamId);

	useEffect(() => {
		setTeamData(teamId);
	}, [teamId]);

	useEffect(() => {
		setUserData(user);
	}, [user]);

	// Connect to socket
	useEffect(() => {
		socket.emit("new-user-add", userData?._id);
		socket.on("get-users-online", (data) => {
			setOnlineUsers(data);
		});
	}, [userData]);

	const getTeams = useCallback(async () => {
		try {
			const { success, message, data } = await getSingleTeam(teamData);
			if (success === true) {
				const five_members = data.members.slice(0, 5);
				setFiveMembers(five_members);
			} else {
				console.log("SERVER_ERROR:", message);
			}
		} catch (err) {
			console.error("Error:", err.message);
		}
	}, [teamData]);

	useEffect(() => {
		getTeams();
	}, [getTeams]);

	// Check if the user is online
	const checkOnlineStatus = (data) => {
		const online = onlineUsers?.find((user) => user.userId === data?._id);
		return online ? true : false;
	};

	const TeamMember = (props) => {
		const { profile_image, first_name, last_name } = props;
		const name = `${first_name.charAt(0).toUpperCase()}${first_name.slice(
			1,
		)} ${last_name.charAt(0).toUpperCase()}${last_name.slice(1)}`;

		return (
			<ListGroup.Item className="px-0">
				<Row className="align-items-center">
					<Col xs="auto">
						<Card.Link href={name} className="avatar">
							{profile_image ? (
								<Image src={profile_image} className="avatar rounded m-0" />
							) : (
								<div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary m-0">
									<span>{getInitialsOfFirstTwoWords(name)}</span>
								</div>
							)}
						</Card.Link>
					</Col>
					<Col xs="auto" className="ms--2">
						<h4 className="h6 mb-0">
							<Card.Link href={`#${name}`}>{capitalizeFirstLetter(name)}</Card.Link>
						</h4>
						<div className="d-flex align-items-center" style={{ fontSize: "0.8rem" }}>
							<div
								className={`bg-${
									checkOnlineStatus(props) ? "success" : "gray-200"
								} dot rounded-circle me-1`}
							/>
							<small>{checkOnlineStatus(props) ? "Online" : "Offline"}</small>
						</div>
					</Col>
					<Col className="text-end">
						<Button
							variant="secondary"
							size="sm"
							className="d-inline-flex align-items-center"
							as={Link}
							to={URLs.Profile.path + "/" + props?._id}
						>
							<EyeIcon className="icon icon-xs me-2" />
							View
						</Button>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	};

	return (
		<Card border="0" className="shadow">
			<Card.Header className="border-bottom d-flex align-items-center justify-content-between">
				<h2 className="fs-5 fw-bold mb-0">Team members</h2>
				<Button
					variant="primary"
					size="sm"
					onClick={() => navigate(URLs.TeamMembers.path + "/" + teamId)}
				>
					See all
				</Button>
			</Card.Header>
			<Card.Body>
				<ListGroup className="list-group-flush list my--3">
					{fiveMembers?.map((tm) => (
						<TeamMember key={`team-member-${tm?._id}`} {...tm} />
					))}
				</ListGroup>
			</Card.Body>
		</Card>
	);
};

export default TeamFiveTeamMembers;
