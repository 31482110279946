import httpService from "./httpService";

export const createTask = async (projectId, form) => {
	try {
		const response = await httpService.post(
			`/tasks/create-task/${projectId}`,
			form,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't create task, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const updateTaskStatus = async (taskId, form) => {
	try {
		const response = await httpService.patch(
			`/tasks/change-status/${taskId}`,
			form,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't update task, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const deleteTask = async (taskId) => {
	try {
		const response = await httpService.delete(`/tasks/delete-task/${taskId}`);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't delete task, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const updateTaskPriority = async (form) => {
	const { taskId, priority } = form;

	try {
		const response = await httpService.patch(`/tasks/set-priority/${taskId}`, {
			priority: priority,
		});
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't update priority, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const assignTask = async (form) => {
	const { taskId, userId } = form;
	try {
		const response = await httpService.patch(`/tasks/assign-task/${taskId}`, {
			userId: userId,
		});
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't assign task, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const unassignTask = async (form) => {
	const { taskId, userId } = form;

	try {
		const response = await httpService.patch(`/tasks/unassign-task/${taskId}`, {
			userId: userId,
		});
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't unassign task, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllTasks = async () => {
	try {
		const res = await httpService.get("/tasks/all-tasks");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getSingleTask = async (id) => {
	try {
		const res = await httpService.get(`/tasks/single-task/${id}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getMyTask = async () => {
	try {
		const res = await httpService.get("/tasks/my-tasks");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const changeDueDate = async (taskId, form) => {
	try {
		const response = await httpService.patch(
			`/tasks/change-due-date/${taskId}`,
			form,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't change due date, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const postComment = async (taskId, form) => {
	try {
		const response = await httpService.post(
			`/comments/create-comment/${taskId}`,
			form,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't post comment, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const deleteComment = async (taskId, commentId) => {
	try {
		const response = await httpService.delete(
			`/comments/delete-comment/${taskId}/${commentId}`,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't delete comment, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const uploadAttachment = async (taskId, form) => {
	try {
		const response = await httpService.post(
			`/tasks/upload-attachment/${taskId}`,
			form,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't upload attachment, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const deleteAttachment = async (taskId, attachmentId) => {
	try {
		const response = await httpService.delete(
			`/tasks/upload-attachment/${taskId}/${attachmentId}`,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't delete attachment, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const createSubTask = async (taskId, form) => {
	try {
		const response = await httpService.post(
			`/tasks/create-sub-task/${taskId}`,
			form,
		);
		return response.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const assignSubTask = async (form) => {
	const { taskId, userId } = form;
	try {
		const response = await httpService.patch(`/tasks/assign-sub-task/${taskId}`, {
			userId: userId,
		});
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't assign task, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const unassignSubTask = async (form) => {
	const { taskId, userId } = form;
	try {
		const response = await httpService.patch(`/tasks/unassign-sub-task/${taskId}`, {
			userId: userId,
		});
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't unassign task, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
