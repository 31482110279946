import React, { useRef, useEffect, useState } from "react";

import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import interactionPlugin from "@fullcalendar/interaction";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { deleteEvent, getAllEvents } from "services/calendarService";
import AddEventModal from "components/AddEventModal";
import { useDispatch } from "react-redux";
import { getUser } from "redux/actions/userActions";
import ViewEventModal from "components/ViewEventModal";
import { realtimeUpdate } from "shared/socket";

const SwalWithBootstrapButtons = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary me-3",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

const Calendar = () => {
	const defaultModalProps = {
		id: "",
		title: "",
		start: null,
		end: null,
		allDay: false,
		className: "",
		address: "",
		location: "",
		organizer: "",
		startTime: "",
		stopTime: "",
	};

	const dispatch = useDispatch();
	const [events, setEvents] = useState([]);
	const [eventData, setEventData] = useState([]);

	const getEvents = async () => {
		try {
			const { success, data, message } = await getAllEvents();
			if (success === true) {
				setEvents(data.map((e) => ({ ...e, show: true })));
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	};

	useEffect(() => {
		getEvents();
	}, []);

	useEffect(() => {
		realtimeUpdate.on("event-updated", (data) => {
			if (data) {
				getEvents();
				dispatch(getUser());
			}
		});
	}, [dispatch]);

	useEffect(() => {
		setEventData(
			events.map((event) => {
				const start = moment.utc(event?.start).toDate();
				const end = moment.utc(event?.end).toDate();

				return {
					title: event?.title,
					allDay: event?.allDay,
					className: event?.className,
					author: event?.author,
					start: start,
					end: end,
					id: event?._id,
					address: event?.address,
					location: event?.location,
					organizer: event?.organizer,
					eventStartTime: event?.startTime,
					eventStopTime: event?.startTime,
					description: event?.description,
				};
			}),
		);
	}, [events]);

	const [showViewModal, setShowViewModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);
	const [modalProps, setModalProps] = useState(defaultModalProps);
	const calendarRef = useRef();

	const currentDate = moment().format("YYYY-MM-DD");

	const onDateClick = (props) => {
		const id = eventData.length + 1;
		const date = new Date(props.date);
		const endDate = moment(date).endOf("day").add(1, "day").toDate();

		setModalProps({ ...defaultModalProps, id, start: date, end: endDate });
		setShowAddModal(true);
	};

	const onEventClick = (props) => {
		const event = props.event;

		const data = {
			id: event.id,
			title: event.title,
			author: event.extendedProps.author,
			start: event.start,
			end: event.end,
			startTime: event.extendedProps.eventStartTime,
			stopTime: event.extendedProps.eventStopTime,
			description: event.extendedProps.description,
			allDay: event.allDay,
			classNames: event.classNames,
			address: event.extendedProps.address,
			location: event.extendedProps.location,
			organizer: event.extendedProps.organizer,
		};
		setModalProps(data);
		setShowViewModal(true);
	};

	const onEventUpdate = (props) => {
		const { id, title, start, end, sameDay } = props;
		const calendarApi = calendarRef.current.getApi();
		const calendarElem = calendarApi.getEventById(id);

		if (calendarElem) {
			calendarElem.setProp("title", title);
			calendarElem.setDates(start, end, { allDay: sameDay });
		}

		setShowViewModal(false);
	};

	const onEventDelete = async (id) => {
		const result = await SwalWithBootstrapButtons.fire({
			icon: "error",
			title: "Confirm deletion",
			text: "Are you sure you want to delete this event?",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		setShowViewModal(false);
		setModalProps(defaultModalProps);

		if (result.isConfirmed) {
			try {
				const response = await deleteEvent(id);
				if (response.success === true) {
					toast(response.message, { type: "success", autoClose: 3000 });
					getEvents();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire(
						"Deleted!",
						"The event has been deleted.",
						"success",
					);
				} else {
					toast(response.message, { type: "error", autoClose: 3000 });
				}
			} catch (err) {
				console.log("SERVER_ERROR::", err.message);
			}
		}
	};

	const handleClose = () => {
		setShowAddModal(false);
		setShowViewModal(false);
	};

	const eventTimeFormat = {
		hour: "numeric",
		minute: "2-digit",
		meridiem: "short",
		timeZoneName: "short",
	};

	return (
		<>
			{showViewModal ? (
				<ViewEventModal
					{...modalProps}
					edit={true}
					showModal={showViewModal}
					onUpdate={onEventUpdate}
					onDelete={onEventDelete}
					setShowModal={handleClose}
				/>
			) : null}

			{showAddModal ? (
				<AddEventModal showModal={showAddModal} setShowModal={handleClose} />
			) : null}

			{/* <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb
						className="d-none d-md-inline-block"
						listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
					>
						<Breadcrumb.Item>
							<HomeIcon className="icon icon-xs" />
						</Breadcrumb.Item>
						<Breadcrumb.Item>Amebo Board</Breadcrumb.Item>
						<Breadcrumb.Item active>Calendar and Events</Breadcrumb.Item>
					</Breadcrumb>
					<h4>Calendar and Events</h4>
				</div>
				<div></div>
			</div> */}

			<Card className="border-0 shadow calendar-card">
				<Card.Body>
					<FullCalendar
						editable
						selectable
						ref={calendarRef}
						events={eventData}
						displayEventTime={false}
						themeSystem="bootstrap"
						plugins={[
							dayGridPlugin,
							timeGridPlugin,
							bootstrapPlugin,
							interactionPlugin,
						]}
						initialView="dayGridMonth"
						buttonText={{
							prev: "Previous",
							next: "Next",
							month: "Month",
							week: "Week",
							day: "Day",
							today: "Today",
						}}
						bootstrapFontAwesome={false}
						initialDate={currentDate}
						eventTimeFormat={eventTimeFormat}
						eventClick={onEventClick}
						dateClick={onDateClick}
					/>
				</Card.Body>
			</Card>
		</>
	);
};

export default Calendar;
