import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
	Col,
	Row,
	Form,
	Button,
	InputGroup,
	Pagination,
	Breadcrumb,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { realtimeUpdate } from "shared/socket";
import { URLs } from "routes";
import {
	ArchiveIcon,
	HomeIcon,
	PlusIcon,
	SearchIcon,
} from "@heroicons/react/solid";
import ProjectCard from "components/ProjectCard";
import { useDispatch } from "react-redux";
import CreateProjectModal from "components/CreateProjectModal";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";
import { deleteProject, getAllProjects } from "services/projectService";

const SwalWithBootstrapButtons = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary me-3",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	}),
);

const DashboardProjects = () => {
	const dispatch = useDispatch();
	const [projects, setProjects] = useState([]);

	const [projectData, setProjectData] = useState(
		projects?.map((p) => ({ ...p, show: true })),
	);

	const getProjects = useCallback(async () => {
		try {
			const { success, data, message } = await getAllProjects();
			if (success === true) {
				setProjects(data.map((p) => ({ ...p, show: true })));
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, []);

	useEffect(() => {
		getProjects();
	}, [getProjects]);

	useEffect(() => {
		realtimeUpdate.on("project-updated", (data) => {
			if (data) {
				getProjects();
				dispatch(getUser());
			}
		});
	}, [dispatch, getProjects]);

	useEffect(() => {
		const updatedProjects = projects?.map((p) => ({
			...p,
			show: true,
		}));
		setProjectData(updatedProjects);
	}, [projects]);

	const [showModal, setShowModal] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const handleNewCardClick = () => {
		setShowModal(true);
	};

	const projectDelete = async (id) => {
		const textMessage = "Are you sure you want to delete this project?";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "error",
			title: "Confirm delete",
			text: textMessage,
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			try {
				const response = await deleteProject(id);
				if (response.success === true) {
					getProjects();
					dispatch(getUser());
					await SwalWithBootstrapButtons.fire(
						"Deleted",
						response.message,
						"success",
					);
				}
			} catch (err) {
				await SwalWithBootstrapButtons.fire("Error", err.message, "error");
			}
		}
	};

	const changeSearchValue = (e) => {
		const newSearchValue = e.target.value;
		const newProjects = projectData.map((p) => {
			const title = p.title.toLowerCase();
			const description = p.description.toLowerCase();

			const shouldShow =
				title.includes(newSearchValue) ||
				description.includes(newSearchValue) ||
				`${p.due_date}`.includes(newSearchValue);

			return {
				...p,
				show: shouldShow,
				isSelected: shouldShow && p.isSelected ? false : p.isSelected,
			};
		});

		setProjectData(newProjects);
		setSearchValue(newSearchValue);
	};

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentProjects = projectData.slice(indexOfFirstItem, indexOfLastItem);

	// Change page
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div className="d-block mb-4 mb-md-0">
					<Breadcrumb
						className="d-none d-md-inline-block"
						listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
					>
						<Breadcrumb.Item>
							<HomeIcon className="icon icon-xs" />
						</Breadcrumb.Item>
						<Breadcrumb.Item>Amebo Board</Breadcrumb.Item>
						<Breadcrumb.Item active>Project List</Breadcrumb.Item>
					</Breadcrumb>
					<h4>Project List</h4>
				</div>
				<div className="btn-toolbar mb-2 mb-md-0">
					<Button variant="gray-800" as={Link} to={URLs.Repositories.path}>
						<ArchiveIcon className="icon icon-xs text-white" /> View Repository
					</Button>
				</div>
			</div>

			<div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<Col xs="auto" className="d-flex justify-content-between ps-0 mb-4 mb-lg-0">
					<div className="me-lg-3">
						<Button
							as={Button}
							variant="secondary"
							className="d-inline-flex align-items-center me-2"
							onClick={handleNewCardClick}
						>
							<PlusIcon className="icon icon-xs me-2" /> New Project
						</Button>
					</div>
				</Col>

				<Col xs={12} lg={6}>
					<InputGroup className="d-flex justify-content-lg-end input-group-merge search-bar">
						<InputGroup.Text>
							<SearchIcon className="icon icon-xs" />
						</InputGroup.Text>
						<Form.Control
							type="text"
							placeholder="Search Projects Here"
							className="w-100 fmxw-300"
							value={searchValue}
							onChange={changeSearchValue}
						/>
					</InputGroup>
				</Col>
			</div>

			<div className="task-wrapper border bg-white border-light shadow-sm py-1 rounded">
				{currentProjects
					.filter((p) => p.show)
					.map((p) => (
						<ProjectCard
							{...p}
							getProjects={getProjects}
							key={`task-${p?._id}`}
							projectDelete={(id) => projectDelete(id)}
						/>
					))}

				<Row className="d-flex align-items-center p-4">
					<div className="col-md-4 d-flex align-items-center mb-3 mb-md-0">
						<span className="me-2">Page:</span>
						<Pagination className="mb-0">
							<Pagination.Prev
								disabled={currentPage === 1}
								onClick={() => handlePageChange(currentPage - 1)}
							>
								Prev
							</Pagination.Prev>
							{Array.from(
								{ length: Math.ceil(projectData.length / itemsPerPage) },
								(_, i) => (
									<Pagination.Item
										key={i}
										active={i + 1 === currentPage}
										onClick={() => handlePageChange(i + 1)}
									>
										{i + 1}
									</Pagination.Item>
								),
							)}
							<Pagination.Next
								disabled={currentPage === Math.ceil(projectData.length / itemsPerPage)}
								onClick={() => handlePageChange(currentPage + 1)}
							>
								Next
							</Pagination.Next>
						</Pagination>
					</div>
					<div className="col-md-4 d-flex align-items-center mb-3 mb-md-0">
						<span className="me-2">Show:</span>
						<select
							className="form-select form-select-sm"
							style={{ width: "80px", paddingRight: "1rem" }}
							onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
							value={itemsPerPage}
						>
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={15}>15</option>
						</select>
						<span className="mx-2">entries</span>
					</div>
					<div className="col-md-4 d-flex align-items-center">
						<span className="me-2">Showing:</span>
						<span className="fw-normal">
							{currentProjects.length} out of {projectData.length} entries
						</span>
					</div>
				</Row>
			</div>
			<CreateProjectModal
				updateProject={getProjects}
				showModal={showModal}
				setShowModal={setShowModal}
			/>
		</>
	);
};

export default DashboardProjects;
