import React, { useState } from "react";
import {
	ArchiveIcon,
	DotsHorizontalIcon,
	EyeIcon,
	TrashIcon,
} from "@heroicons/react/solid";
import {
	Col,
	Card,
	Form,
	Button,
	Dropdown,
	Modal,
	ListGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { URLs } from "routes";
import {
	archiveProject,
	doneProject,
	suspendProject,
} from "services/projectService";
import { useDispatch } from "react-redux";
import { fetchSingleProject } from "redux/actions/singleProjectActions";
import { toast } from "react-toastify";

const ProjectCard = (props) => {
	const { _id, name, suspended, done, archived, description, getProjects } =
		props;
	const [showEdit, setShowEdit] = useState(false);
	const dispatch = useDispatch();

	const handleShowEdit = () => {
		setShowEdit(!showEdit);
	};
	const projectDelete = () => {
		props.projectDelete && props.projectDelete(_id);
	};

	const handleSuspendToggle = async () => {
		const { message, success } = await suspendProject(_id);
		if (success === true) {
			dispatch(fetchSingleProject(_id));
			getProjects();
			handleShowEdit();
			toast(message, { type: "success", autoClose: 5000 });
		} else {
			toast(message, { type: "error", autoClose: 5000 });
		}
	};

	const handleDoneToggle = async () => {
		const { message, success } = await doneProject(_id);
		if (success === true) {
			dispatch(fetchSingleProject(_id));
			getProjects();
			handleShowEdit();
			toast(message, { type: "success", autoClose: 5000 });
		} else {
			toast(message, { type: "error", autoClose: 5000 });
		}
	};

	const handleArchiveToggle = async () => {
		const { message, success } = await archiveProject(_id);
		if (success === true) {
			dispatch(fetchSingleProject(_id));
			getProjects();
			handleShowEdit();
			toast(message, { type: "success", autoClose: 5000 });
		} else {
			toast(message, { type: "error", autoClose: 5000 });
		}
	};

	return (
		<>
			<Card border="bottom" className="hover-state rounded-0 rounded-top py-3">
				<Card.Body className="d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0">
					<Col xs={11} lg={10} className="px-0 mb-4 mb-md-0">
						<div className="mb-2">
							<h5 className="">{name}</h5>
						</div>
						<div className="fw-bold text-dark">
							<span className={`fw-normal text-gray`}>{description}</span>
						</div>
					</Col>
					<Col
						xs={10}
						sm={1}
						lg={1}
						xl={1}
						className="d-flex align-items-center ms-lg-auto text-right justify-content-center px-md-0"
					>
						<Dropdown className="d-flex justify-content-end">
							<Dropdown.Toggle
								as={Button}
								variant="link"
								className="text-dark dropdown-toggle-split m-0 p-0"
							>
								<DotsHorizontalIcon className="icon icon-xs icon-dark" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
								{/* <Dropdown.Item
									as={Button}
									variant="link"
									className={`d-flex align-items-center text-success`}
								>
									<PencilAltIcon className="dropdown-icon me-2" /> Edit
								</Dropdown.Item> */}
								<Dropdown.Item
									variant="link"
									className="d-flex align-items-center text-purple"
									as={Link}
									to={URLs.Kanban.path + "/" + _id}
								>
									<EyeIcon className="dropdown-icon  me-2" /> View Project
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center text-gray-500"
									onClick={handleShowEdit}
								>
									<ArchiveIcon className="dropdown-icon me-2" /> Move
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center text-danger"
									onClick={projectDelete}
								>
									<TrashIcon className="dropdown-icon me-2" /> Delete
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Card.Body>
			</Card>
			<Modal
				as={Modal.Dialog}
				centered
				show={showEdit}
				onHide={() => setShowEdit(false)}
			>
				<Form className="modal-content p-3">
					<Modal.Header className="pb-0 border-0">
						<h5 as={Modal.Title} className="fw-normal">
							Select Directory
						</h5>
						<Button variant="close" onClick={() => setShowEdit(false)} />
					</Modal.Header>
					<Modal.Body className="pb-0">
						<ListGroup className="list-group-flush">
							<ListGroup.Item
								className={`d-flex align-items-center justify-content-between px-0 border-bottom`}
							>
								<div>
									<Card.Text className="h6 mb-1">Suspend</Card.Text>
									<p className="small pe-4">
										This will move the project to Suspended Directory
									</p>
								</div>
								<div>
									<Form.Check className="form-switch">
										<Form.Check.Input
											defaultChecked={suspended}
											onChange={handleSuspendToggle}
											type="checkbox"
										/>
									</Form.Check>
								</div>
							</ListGroup.Item>
							<ListGroup.Item
								className={`d-flex align-items-center justify-content-between px-0 border-bottom`}
							>
								<div>
									<Card.Text className="h6 mb-1">Done</Card.Text>
									<p className="small pe-4">
										This will move the project to Done Directory
									</p>
								</div>
								<div>
									<Form.Check className="form-switch">
										<Form.Check.Input
											defaultChecked={done}
											onChange={handleDoneToggle}
											type="checkbox"
										/>
									</Form.Check>
								</div>
							</ListGroup.Item>
							<ListGroup.Item
								className={`d-flex align-items-center justify-content-between px-0`}
							>
								<div>
									<Card.Text className="h6 mb-1">Archive</Card.Text>
									<p className="small pe-4">
										This will move the project to Archive Directory
									</p>
								</div>
								<div>
									<Form.Check className="form-switch">
										<Form.Check.Input
											defaultChecked={archived}
											onChange={handleArchiveToggle}
											type="checkbox"
										/>
									</Form.Check>
								</div>
							</ListGroup.Item>
						</ListGroup>
					</Modal.Body>
					<Modal.Footer className="justify-content-start border-0 pt-0"></Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default ProjectCard;
