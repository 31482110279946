import React, { useCallback, useState, useEffect } from "react";
import { ClipboardListIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Button, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { URLs } from "routes";
import { getOrgProgressTracker } from "services/analyticsService";

const OrgProgressTrack = () => {
	const navigate = useNavigate();
	const [progressData, setProgressData] = useState([]);
	
	const getData = useCallback(async () => {
		try {
			const { success, message, data } = await getOrgProgressTracker();
			if (success === true) {
				setProgressData(data);
			} else {
				console.log("SERVER_ERROR:", message);
			}
		} catch (err) {
			console.error("Error:", err.message);
		}
	}, []);

	useEffect(() => {
		getData();
	}, [getData]);

	const Progress = (props) => {
		const { title, percentage, color, last = false } = props;
		const rowClassName = last ? "mb-3" : "mb-4";

		return (
			<Row className={`d-flex align-items-center ${rowClassName}`}>
				<Col xs="auto">
					<ClipboardListIcon className="icon icon-sm text-gray-500" />
				</Col>
				<Col>
					<div className="progress-wrapper">
						<div className="progress-info">
							<h6 className="mb-0">{title}</h6>
							<small className="fw-bold text-gray-500">
								<span>{percentage} %</span>
							</small>
						</div>
						<ProgressBar
							variant={color}
							now={percentage}
							min={0}
							max={100}
							className="mb-0"
						/>
					</div>
				</Col>
			</Row>
		);
	};

	return (
		<Card border="0" className="shadow">
			<Card.Header className="border-bottom d-flex align-items-center justify-content-between">
				<h2 className="fs-5 fw-bold mb-0">Progress track</h2>
				<Button
					variant="primary"
					size="sm"
					onClick={() => navigate(URLs.DashboardProjects.path)}
				>
					See tasks
				</Button>
			</Card.Header>
			<Card.Body>
				{progressData.length > 0 ? (
					progressData.map((item, index) => {
						return (
							<Progress
								key={index}
								title={item.title}
								color={item.color}
								percentage={item.percentage}
								last={index === progressData.length - 1}
							/>
						);
					})
				) : (
					<div className="text-center">
						<h3>No task yets</h3>
					</div>
				)}
			</Card.Body>
		</Card>
	);
};

export default OrgProgressTrack;
