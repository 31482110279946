import React, { useState, useEffect, useCallback } from "react";
import { BellIcon, ClockIcon } from "@heroicons/react/solid";
import { Col, Row, Card, ListGroup } from "react-bootstrap";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { getAllNotifications } from "services/notificationService";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";
import { realtimeUpdate } from "shared/socket";

const Timeline = (props) => {
	const dispatch = useDispatch();
	const [notifications, setNotifications] = useState([]);

	const getNotifications = useCallback(async () => {
		try {
			const { success, message, data } = await getAllNotifications();
			if (success === true) {
				setNotifications(data);
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, []);

	useEffect(() => {
		getNotifications();
	}, [getNotifications]);

	useEffect(() => {
		realtimeUpdate.on("notification-updated", (data) => {
			if (data) {
				getNotifications();
				dispatch(getUser());
			}
		});
	}, [dispatch, getNotifications]);

	const TimelineItem = ({ notif }) => {
		return (
			<ListGroup.Item className="border-o">
				<Row className="ps-lg-1">
					<Col xs="auto">
						<div className={`icon-shape icon-xs icon-shape-primary rounded`}>
							<BellIcon />
						</div>
					</Col>
					<Col className="ms-n2 mb-4">
						<h3 className="fs-6 fw-bold mb-1">{notif?.tag}</h3>
						<Card.Text className="mb-1">{notif?.msg}</Card.Text>
						<div className="d-flex align-items-center">
							<ClockIcon className="icon icon-xxs text-gray-400 me-1" />
							<small>
								<Moment fromNow>{notif?.createdAt}</Moment>
							</small>
						</div>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	};

	return (
		<Card border="0" className="notification-card shadow">
			<Card.Header className="d-flex align-items-center">
				<h2 className="fs-5 fw-bold mb-0">Sneak peek</h2>
				<div className="ms-auto">
					{/* <Card.Link  className="d-inline-flex align-items-center fw-normal">
						<EyeIcon className="icon icon-xxs me-2" />
						View all
					</Card.Link> */}
				</div>
			</Card.Header>
			<Card.Body>
				{notifications && notifications.length > 0 ? (
					<ListGroup className="list-group-flush list-group-timeline">
						{notifications.map((notif) => (
							<TimelineItem key={`notif-${notif?._id}`} notif={notif} />
						))}
					</ListGroup>
				) : (
					<p style={{ textAlign: "center", color: "gray", fontStyle: "italic" }}>
						No notifications available
					</p>
				)}
			</Card.Body>
		</Card>
	);
};

export default Timeline;
