import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
	DotsHorizontalIcon,
	EyeIcon,
	PencilAltIcon,
	TrashIcon,
	UserAddIcon,
	UserRemoveIcon,
	ChartPieIcon,
} from "@heroicons/react/solid";

import { Col, Card, Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import AddTeamMemberModal from "./AddTeamMemberModal";
import ViewTeamMembersModal from "./ViewTeamMembersModal";
import EditTeam from "./EditTeam";
import RemoveTeamMember from "./RemoveTeamMember";
import { deleteTeam } from "services/teamService";
import { URLs } from "routes";

const TeamCard = (props) => {
	const { _id, name, description, members, isUpdated, setIsUpdated, getTeams } =
		props;
	const navigate = useNavigate();
	const [showViewMember, setShowViewMember] = useState(false);
	const [showAddMember, setShowAddMember] = useState(false);
	const [showRemoveMember, setShowRemoveMember] = useState(false);
	const [showEdit, setShowEdit] = useState(false);

	const [isMounted, setIsMounted] = useState(false);

	const handleShowViewMember = () => {
		setShowViewMember(!showViewMember);
		setIsMounted(true);
	};

	useEffect(() => {
		if (isMounted) {
			getTeams();
			setIsUpdated(!isUpdated);
			setIsMounted(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMounted, isUpdated]);

	const handleShowAddMember = () => {
		setShowAddMember(!showAddMember);
	};
	const handleShowRemoveMember = () => {
		setShowRemoveMember(!showRemoveMember);
	};
	const handleShowEdit = () => {
		setShowEdit(!showEdit);
	};

	const deleteTeamHandler = () => {
		Swal.fire({
			title: "Are you sure you?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				// Perform delete action here
				try {
					const response = await deleteTeam(_id);
					const { success, message } = response;
					if (success === true) {
						Swal.fire("Deleted!", `${message}`, "success");
						setIsUpdated(!isUpdated);
					} else {
						Swal.fire("Error", `${message}`, "error");
					}
				} catch (error) {
					Swal.fire("Error", `${error.message}`, "error");
				}
			}
		});
	};

	return (
		<>
			<Card border="bottom" className="hover-state rounded-0 rounded-top py-3">
				<Card.Body className="d-sm-flex align-items-center flex-wrap flex-lg-nowrap py-0">
					<Col xs={11} lg={10} className="px-0 mb-4 mb-md-0">
						<div className="mb-2">
							<h5 className="">{name}</h5>
						</div>
						<div className="fw-bold text-dark">
							<span className={`fw-normal text-gray`}>{description}</span>
						</div>
					</Col>
					<Col
						xs={10}
						sm={1}
						lg={1}
						xl={1}
						className="d-flex align-items-center ms-lg-auto text-right justify-content-center px-md-0"
					>
						<Dropdown className="d-flex justify-content-end">
							<Dropdown.Toggle
								as={Button}
								variant="link"
								className="text-dark dropdown-toggle-split m-0 p-0"
							>
								<DotsHorizontalIcon className="icon icon-xs icon-dark" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
								<Dropdown.Item
									as={Button}
									variant="link"
									className={`d-flex align-items-center`}
									onClick={handleShowEdit}
								>
									<PencilAltIcon className="dropdown-icon me-2" /> Edit
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center"
									onClick={() => navigate(`${URLs.DashboardTeams.path}/${_id}/stats`)}
								>
									<ChartPieIcon className="dropdown-icon me-2" /> View Analytics
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center"
									onClick={handleShowViewMember}
								>
									<EyeIcon className="dropdown-icon me-2" /> View Members
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center"
									onClick={handleShowAddMember}
								>
									<UserAddIcon className="dropdown-icon me-2" /> Add Members
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center"
									onClick={handleShowRemoveMember}
								>
									<UserRemoveIcon className="dropdown-icon me-2" /> Remove Members
								</Dropdown.Item>
								<Dropdown.Item
									as={Button}
									variant="link"
									className="d-flex align-items-center text-danger"
									onClick={() => deleteTeamHandler()}
								>
									<TrashIcon className="dropdown-icon me-2" /> Delete
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Card.Body>
			</Card>
			<AddTeamMemberModal
				showModal={showAddMember}
				setShowModal={setShowAddMember}
				setIsUpdated={setIsUpdated}
				isUpdated={isUpdated}
				teamID={_id}
				membersData={members}
			/>
			<RemoveTeamMember
				showModal={showRemoveMember}
				setShowModal={setShowRemoveMember}
				setIsUpdated={setIsUpdated}
				isUpdated={isUpdated}
				teamID={_id}
				membersData={members}
			/>
			<ViewTeamMembersModal
				showModal={showViewMember}
				setShowModal={setShowViewMember}
				setIsUpdated={setIsUpdated}
				isUpdated={isUpdated}
				membersData={members}
				getTeams={getTeams}
				title={name}
			/>
			<EditTeam
				setShowEdit={setShowEdit}
				showEdit={showEdit}
				setIsUpdated={setIsUpdated}
				isUpdated={isUpdated}
				teamID={_id}
				title={name}
				description={description}
			/>
		</>
	);
};

export default TeamCard;
