import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { EyeIcon } from "@heroicons/react/solid";
import { Col, Row, Card } from "react-bootstrap";
import { realtimeUpdate } from "shared/socket";
import { Link } from "react-router-dom";
import { URLs } from "routes";
import { useDispatch } from "react-redux";
import { getAllEvents } from "services/calendarService";
import { toast } from "react-toastify";
import { getUser } from "redux/actions/userActions";

const Event = () => {
	const dispatch = useDispatch();
	const [events, setEvents] = useState([]);

	const [allEvents, setAllEvents] = useState(
		events?.map((e) => ({ ...e, show: true })),
	);

	const getEvents = async () => {
		try {
			const { success, data, message } = await getAllEvents();
			if (success === true) {
				setEvents(data.map((e) => ({ ...e, show: true })));
			} else {
				toast(message, { type: "error", autoClose: 3000 });
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	};

	useEffect(() => {
		getEvents();
	}, []);

	useEffect(() => {
		realtimeUpdate.on("event-updated", (data) => {
			if (data) {
				getEvents();
				dispatch(getUser());
			}
		});
	}, [dispatch]);

	useEffect(() => {
		const updatedEvents = events?.map((e) => ({
			...e,
			show: true,
		}));
		setAllEvents(updatedEvents);
	}, [events]);

	const Event = (props) => {
		const {
			organizer,
			startDate,
			endDate,
			location,
			title,
			address,
			onlyTime,
			index,
		} = props;

		const startDateTime = startDate.format("HH:mm A");
		const startDateMonth = startDate.format("MMM");
		const startDateDay = startDate.format("D");
		const startDateFormatted = startDate.format("ddd, D MMM");
		const endDateFormatted = endDate.format("ddd, D MMM YYYY");
		const eventDuration = `${startDateFormatted} - ${endDateFormatted}`;

		return (
			<Row
				className={`align-items-center d-block d-sm-flex ${
					index === 3 ? "" : "border-bottom pb-3 mb-3"
				}`}
			>
				<Col xs="auto" className="mb-3 mb-sm-0">
					<div className="calendar d-flex">
						<span className="calendar-month">{startDateMonth}</span>
						<span className="calendar-day py-2">{startDateDay}</span>
					</div>
				</Col>
				<Col>
					<div className="mb-1">
						<h5 className="mb-1">{title}</h5>
					</div>
					<span>
						Organized by <Card.Link href="#">{organizer}</Card.Link>
					</span>
					<div className="small fw-bold">
						{onlyTime ? `Time: ${startDateTime}` : eventDuration}
					</div>
					<div className="small fw-bold">Place: {address}</div>
					<div className="small fw-bold">Location: {location}</div>
				</Col>
			</Row>
		);
	};

	return (
		<Card border="0" className="shadow">
			<Card.Header className="border-bottom">
				<h2 className="fs-5 fw-bold mb-1">Events</h2>
			</Card.Header>
			<Card.Body>
				{allEvents?.length > 0 ? (
					allEvents
						.slice(0, 1)
						.map((evt, idx) => (
							<Event
								key={evt?._id}
								index={idx + 1}
								organizer={evt?.organizer}
								startDate={moment(evt?.start)}
								endDate={moment(evt?.end)}
								title={evt?.title}
								location={evt?.location}
								address={evt?.address}
								onlyTime={evt?.allDay}
							/>
						))
				) : (
					<div className="text-center">No events yet</div>
				)}
			</Card.Body>
			<Card.Footer className="border-0 bg-transparent d-flex justify-content-center">
				{allEvents?.length > 0 ? (
					<Link
						to={URLs.Calendar.path}
						className="d-flex align-items-center fw-bold"
					>
						<EyeIcon className="icon icon-xs me-2" />
						See all
					</Link>
				) : null}
			</Card.Footer>
		</Card>
	);
};

export default Event;
