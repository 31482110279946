import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import moment from "moment-timezone";
import EditEventModal from "./EditEventModal";
import { useSelector } from "react-redux";

const ViewEventModal = (props) => {
	const { user } = useSelector((state) => state.user);
	const { showModal, setShowModal, ...eventData } = props;
	const [showEdit, setShowEdit] = useState(false);

	const creator = `${props?.author.first_name
		.charAt(0)
		.toUpperCase()}${props?.author.first_name.slice(1)} ${props?.author.last_name
		.charAt(0)
		.toUpperCase()}${props?.author.last_name.slice(1)}`;

	return (
		<>
			<Modal
				as={Modal.Dialog}
				centered
				scrollable
				show={showModal}
				onHide={() => setShowModal(false)}
			>
				<Modal.Header className="pb-0 border-0">
					<h5 as={Modal.Title} className="fw-normal">
						Event Details
					</h5>
					<Button variant="close" onClick={() => setShowModal(false)} />
				</Modal.Header>
				<Modal.Body className="pb-0">
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>Creator:</strong> {creator}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>Title:</strong> {props?.title}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>Description:</strong> {props?.description}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>Organizer:</strong> {props?.organizer}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>Location:</strong> {props?.location}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>Address:</strong> {props?.address}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>Start Date:</strong>{" "}
						{props?.start ? moment(props?.start).format("DD/MM/YYYY") : ""}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>End Date:</strong>{" "}
						{props?.end ? moment(props?.end).format("DD/MM/YYYY") : ""}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>Start Time:</strong> {props?.startTime}
					</p>
					<p className="mb-2 font-italic" style={{ fontSize: "0.9rem" }}>
						<strong>End Time:</strong> {props?.stopTime}
					</p>
				</Modal.Body>
				{user?._id === props?.author?._id ? (
					<Modal.Footer className="justify-content-start border-0 pt-0">
						<Button variant="danger" onClick={() => props?.onDelete(props?.id)}>
							Delete event
						</Button>
						<Button
							variant="secondary"
							className="d-inline-flex align-items-center"
							onClick={() => setShowEdit(true)}
							disabled={showEdit}
						>
							Edit Event
						</Button>
					</Modal.Footer>
				) : null}
			</Modal>
			{showEdit ? (
				<EditEventModal
					data={eventData}
					showEdit={showEdit}
					setShowEdit={setShowEdit}
				/>
			) : null}
		</>
	);
};

export default ViewEventModal;
