import httpService from "./httpService";

export const createProject = async (form) => {
	try {
		const response = await httpService.post("/projects/create-project", form);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't create project, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllProjects = async () => {
	try {
		const res = await httpService.get("/projects/all-projects");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getMemberAllProjects = async (memberId) => {
	try {
		const res = await httpService.get(`/projects/member-all-projects/${memberId}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getSingleProject = async (id) => {
	try {
		const res = await httpService.get(`/projects/single-project/${id}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getMyProject = async () => {
	try {
		const res = await httpService.get("/projects/my-projects");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const deleteProject = async (id) => {
	try {
		const res = await httpService.delete(`/projects/delete-project/${id}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const uploadProjectAttachment = async (projectId, form) => {
	try {
		const response = await httpService.post(
			`/projects/upload-attachment/${projectId}`,
			form,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't upload attachment, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const deleteProjectAttachment = async (projectId, attachmentId) => {
	try {
		const response = await httpService.delete(
			`/projects/upload-attachment/${projectId}/${attachmentId}`,
		);
		return response.data;
	} catch (err) {
		let errorMessage = "Couldn't delete attachment, Please try again later.";
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const archiveProject = async (id) => {
	try {
		const res = await httpService.put(`/projects/archive-project/${id}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const doneProject = async (id) => {
	try {
		const res = await httpService.put(`/projects/done-project/${id}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const suspendProject = async (id) => {
	try {
		const res = await httpService.put(`/projects/suspend-project/${id}`);
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllDoneProjects = async () => {
	try {
		const res = await httpService.get("/projects/all-done-projects");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllSuspendedProjects = async () => {
	try {
		const res = await httpService.get("/projects/all-suspended-projects");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};

export const getAllArchivedProjects = async () => {
	try {
		const res = await httpService.get("/projects/all-archived-projects");
		return res.data;
	} catch (err) {
		let errorMessage;
		if (err.response && err.response.data && err.response.data.message) {
			errorMessage = err.response.data.message;
		}
		throw new Error(errorMessage);
	}
};
