import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { realtimeUpdate } from "shared/socket";
import KanbanHeader from "components/KanbanHeader";
import KanbanList from "components/KanbanList";
import KanbanCard from "components/KanbanCard";
import {
	KanbanCreateModal,
	KanbanEditModal,
	KanbanCopyModal,
	KanbanMoveModal,
	KanbanEditMembersModal,
	KanbanEditLabelsModal,
	KanbanAttachmentModal,
	KanbanDueDateModal,
	KanbanViewAttachmentModal,
	KanbanSubTaskModal,
	KanbanAddSubTaskModal,
	KanbanEditSubMembersModal,
} from "components/KanbanModals";
import { createCard, createList } from "data/kanban";
import { ArchiveIcon } from "@heroicons/react/solid";
import { deleteTask, updateTaskStatus } from "services/taskService";
import { toast } from "react-toastify";

import { getSingleProject } from "services/projectService";
import { ViewProjectAttachmentModal } from "components/ViewProjectAttachmentModal";
import { AddProjectAttachmentModal } from "components/AddProjectAttachmentModal";
import { getUser } from "redux/actions/userActions";

const ArchiveIconHtml = ReactDOMServer.renderToString(
	<ArchiveIcon className="h-50 w-auto" />,
);

const SwalWithBootstrapButtons = withReactContent(
	Swal.mixin({
		customClass: {
			confirmButton: "btn btn-primary me-3",
			cancelButton: "btn btn-gray",
		},
		buttonsStyling: false,
	})
);

const columnTitles = [
	{ id: "1", status: "todo", title: "To Do" },
	{ id: "2", status: "inprogress", title: "In Progress" },
	{ id: "3", status: "inreview", title: "In Review" },
	{ id: "4", status: "done", title: "Done" },
];

const Kanban = () => {
	const { projectId } = useParams();
	const dispatch = useDispatch();
	const [project, setProject] = useState(null);
	const [kanbanLists, setKanbanLists] = useState(
		columnTitles.map((column) => ({
			id: column.id,
			status: column.status,
			title: column.title,
			tasks: [],
		})),
	);

	const createCardDefaultProps = { listId: kanbanLists[0].id, cardIndex: 0 };
	const [showCreateCardModal, setShowCreateCardModal] = useState(false);
	const [showCreateListModal, setShowCreateListModal] = useState(false);
	const [createCardProps, setCreateCardProps] = useState(createCardDefaultProps);
	const [cardToEdit, setCardToEdit] = useState(null);
	const [cardToCopy, setCardToCopy] = useState(null);
	const [cardToMove, setCardToMove] = useState(null);
	const [cardToChangeMembers, setCardToChangeMembers] = useState(null);
	const [cardToChangeSubMembers, setCardToChangeSubMembers] = useState(null);
	const [cardToChangeLabels, setCardToChangeLabels] = useState(null);
	const [cardToAttach, setCardToAttach] = useState(null);
	const [subTask, setSubTask] = useState(null);
	const [addSubTask, setAddSubTask] = useState(null);
	const [cardToViewAttachment, setCardToViewAttachment] = useState(null);
	const [cardToDueDate, setCardToDueDate] = useState(null);
	const [listToCopy, setListToCopy] = useState(null);
	const [listToMoveIndex, setListToMoveIndex] = useState(null);
	const [isUpdated, setIsUpdated] = useState(false);

	const updateProject = useCallback(async () => {
		try {
			const { success, data } = await getSingleProject(projectId);
			if (success === true) {
				setProject(data);
				dispatch(getUser());
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}
	}, [projectId, dispatch]);

	useEffect(() => {
		updateProject();
	}, [projectId, updateProject]);

	useEffect(() => {
		realtimeUpdate.on("project-updated", (data) => {
			if (data) {
				updateProject();
			}
		});
	}, [updateProject]);

	useEffect(() => {
		if (isUpdated) {
			const getProject = async () => {
				try {
					const { success, data } = await getSingleProject(projectId);
					if (success === true) {
						setProject(data);
						dispatch(getUser());
					} 
				} catch (err) {
					console.log("SERVER_ERROR::", err.message);
				}
			};
			getProject();
			setIsUpdated(false);
		}
	}, [projectId, dispatch, isUpdated]);

useEffect(() => {
	const updatedColumns = kanbanLists.map((column) => {
		const status = column.status.toLowerCase();
		const tasks = project?.tasks
			.filter((task) => task.status === status)
			.map((task) => ({
				id: task?._id,
				title: task.name,
				description: task.description,
				createdAt: task.createdAt,
				updatedAt: task.updatedAt,
			}))
			.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

		return { ...column, tasks };
	});
	setKanbanLists(updatedColumns);
}, [project, cardToEdit, kanbanLists]);


	const toggleCreateListModal = () => {
		setShowCreateListModal(!showCreateListModal);
	};

	const toggleCreateCardModal = (props = {}) => {
		setCreateCardProps({ ...createCardDefaultProps, ...props });
		setShowCreateCardModal(!showCreateCardModal);
	};

	const getCardStyle = (style, snapshot) => {
		const isJustDragging = snapshot.isDragging && !snapshot.isDropAnimating;

		if (!isJustDragging) {
			return style;
		}

		return {
			...style,
			transform: `${style.transform || ""} rotate(6deg)`,
		};
	};

	const handleCreateCard = (props = {}) => {
		const listsUpdated = createCardInListAtIndex({
			...createCardProps,
			...props,
		});

		toggleCreateCardModal();
		setKanbanLists(listsUpdated);
	};

	const handleCopyCard = (card = {}) => {
		const { listId, title, description } = card;
		const listsUpdated = createCardInListAtIndex({ listId, title, description });

		setCardToCopy(null);
		setKanbanLists(listsUpdated);
	};

	const handleMoveList = ({ source, destination }) => {
		const lists = [...kanbanLists];
		const [listRemoved] = lists.splice(source.index, 1);
		lists.splice(destination.index, 0, listRemoved);

		setKanbanLists(lists);
		setListToMoveIndex(null);
	};

	const handleCreateList = async (props) => {
		const newList = createList(props);
		const listsUpdated = [...kanbanLists, newList];

		setShowCreateListModal(false);
		setKanbanLists(listsUpdated);

		setListToCopy(null);
	};

	const reorderCards = (cards = [], startIndex, endIndex) => {
		const [cardRemoved] = cards.splice(startIndex, 1);
		cards.splice(endIndex, 0, cardRemoved);

		return cards;
	};

	const moveCardFromList = (sList, dList, sIndex, dIndex) => {
		const sCards = [...sList.tasks];
		const dCards = [...dList.tasks];

		const [cardRemoved] = sCards.splice(sIndex, 1);
		dCards.splice(dIndex, 0, cardRemoved);

		return [
			{ ...sList, cards: sCards },
			{ ...dList, cards: dCards },
		];
	};

	const handleDragEnd = async (dragResult) => {
		const { source, destination, draggableId, reason } = dragResult;

		//  dropped outside the list
		if (!destination) {
			return;
		}

		// For actual Drag and Drop
		let taskStatus;
		switch (destination.droppableId) {
			case "1":
				taskStatus = "todo";
				break;
			case "2":
				taskStatus = "inprogress";
				break;
			case "3":
				taskStatus = "inreview";
				break;
			case "4":
				taskStatus = "done";
				break;
			default:
				taskStatus = "";
		}

		try {
			if (draggableId && reason === "DROP") {
				const response = await updateTaskStatus(draggableId, {
					task_status: taskStatus,
				});
				const { message } = response;
					setIsUpdated(true);
					toast(message, { type: "success", autoClose: 3000 });
					dispatch(getUser());
			}
		} catch (err) {
			console.log("SERVER_ERROR::", err.message);
		}

		// For Move by Button Submit
		const { droppableId: sListId, index: sCardIndex } = source;
		const { droppableId: dListId, index: dCardIndex } = destination;

		const sList = kanbanLists.find((l) => l.id === sListId);
		const dList = kanbanLists.find((l) => l.id === dListId);

		if (sListId === dListId) {
			// reorder cards in the list only if card's index changes
			if (sCardIndex !== dCardIndex) {
				const sCardsUpdated = reorderCards(sList.tasks, sCardIndex, dCardIndex);
				const listsUpdated = kanbanLists.map((l) =>
					l.id === sListId ? { ...l, cards: sCardsUpdated } : l,
				);
				setKanbanLists(listsUpdated);
				setIsUpdated(true);
			}
		} else {
			const [sListUpdated, dListUpdated] = moveCardFromList(
				sList,
				dList,
				sCardIndex,
				dCardIndex,
			);
			const listsUpdated = kanbanLists.map((l) =>
				l.id === sListId ? sListUpdated : l.id === dListId ? dListUpdated : l,
			);
			setKanbanLists(listsUpdated);
			setIsUpdated(true);
		}

		if (cardToMove) {
			setCardToMove(null);
		}
	};

	const removeCardsFromList = (cards) => {
		const cardsGroupedByListId = cards.reduce((acc, card) => {
			const { listId, cardId } = card;

			if (!acc[listId]) acc[listId] = [cardId];
			else acc[listId].push(cardId);

			return acc;
		}, {});

		const listsUpdated = kanbanLists.map((l) => {
			const cardsToDelete = cardsGroupedByListId[l.id];
			if (!cardsToDelete) return l;

			const cardsUpdated = l.tasks.filter((c) => !cardsToDelete.includes(c.id));
			return { ...l, cards: cardsUpdated };
		});

		return listsUpdated;
	};

	const handleListDelete = async (listId) => {
		const result = await SwalWithBootstrapButtons.fire({
			icon: "error",
			title: "Confirm deletion",
			text: "Are you sure do you want to delete this list?",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			const listsUpdated = kanbanLists.filter((l) => l.id !== listId);
			setKanbanLists(listsUpdated);

			await SwalWithBootstrapButtons.fire(
				"Deleted",
				"The list has been deleted.",
				"success",
			);
		}
	};

	const handleCardsDelete = async (cards = []) => {
		const textMessage = "Are you sure do you want to delete this card?";

		const result = await SwalWithBootstrapButtons.fire({
			icon: "error",
			title: "Confirm deletion",
			text: textMessage,
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		const cardId = cards[0].cardId;
		if (result.isConfirmed) {
			try {
				const response = await deleteTask(cardId);
				if (response.success) {
					dispatch(getUser());
					const listsUpdated = removeCardsFromList(cards);
					setKanbanLists(listsUpdated);
					setIsUpdated(true);

					await SwalWithBootstrapButtons.fire(
						"Deleted",
						response.message,
						"success",
					);
					return;
				}
			} catch (err) {
				await SwalWithBootstrapButtons.fire("Error", err.message, "error");
			}
		}
	};

	const handleArchiveCards = async (cards = []) => {
		const cardsNr = cards.length;
		const textMessage =
			cardsNr === 1
				? "Are you sure do you want to archive this card?"
				: `Are you sure do you want to archive these ${cardsNr} cards?`;

		const result = await SwalWithBootstrapButtons.fire({
			icon: "question",
			iconHtml: ArchiveIconHtml,
			title: "Confirm archivation",
			text: textMessage,
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
		});

		if (result.isConfirmed) {
			setCardToEdit(null);
			const listsUpdated = removeCardsFromList(cards);
			setKanbanLists(listsUpdated);

			const confirmMessage =
				cardsNr === 1
					? "The card has been archived."
					: "The cards have been archived.";
			await SwalWithBootstrapButtons.fire("Archived", confirmMessage, "success");
		}
	};

	const handleListTitleChange = ({ id, title }) => {
		const listsUpdated = kanbanLists.map((l) =>
			l.id === id ? { ...l, title } : l,
		);
		setKanbanLists(listsUpdated);
	};

	const handleCardChange = (props) => {
		const { listId, cardId, ...otherProps } = props;

		const listsUpdated = kanbanLists.map((l) => {
			if (l.id !== listId) return l;

			const cards = l.tasks.map((c) =>
				c.id === cardId ? { ...c, ...otherProps } : c,
			);

			dispatch(getUser());
			return { ...l, cards };
		});

		if (cardToEdit) {
			setCardToEdit({ ...cardToEdit, ...otherProps });
		}

		setKanbanLists(listsUpdated);
		setCardToChangeMembers(null);
		setIsUpdated(true);
	};

	const createCardInListAtIndex = (props) => {
		const { listId, cardIndex, ...otherProps } = props;

		const listsUpdated = kanbanLists.map((l) => {
			if (listId !== l.id) return l;

			const newCard = createCard(otherProps);
			l.cards.splice(cardIndex, 0, newCard);

			return l;
		});

		return listsUpdated;
	};
	const [showAdd, setShowAdd] = useState(false);
	const [showView, setShowView] = useState(false);

	return (
		<>
			{showView && (
				<ViewProjectAttachmentModal
					projectId={project?._id || projectId}
					project={project}
					showView={showView}
					setShowView={setShowView}
				/>
			)}

			{showAdd && (
				<AddProjectAttachmentModal
					projectId={project?._id || projectId}
					project={project}
					showAdd={showAdd}
					setShowAdd={setShowAdd}
					isUpdated={isUpdated}
				/>
			)}

			{showCreateCardModal && (
				<KanbanCreateModal
					show={showCreateCardModal}
					onHide={toggleCreateCardModal}
					onSubmit={handleCreateCard}
					updateProject={updateProject}
					projectId={project?._id || projectId}
					project={project}
				/>
			)}

			{cardToEdit && (
				<KanbanEditModal
					show={true}
					{...cardToEdit}
					onHide={() => {
						setCardToEdit(null);
					}}
					onMove={(card) => setCardToMove(card)}
					onEditMembers={(card) => setCardToChangeMembers(card)}
					onEditSubMembers={(card) => setCardToChangeSubMembers(card)}
					onEditLabels={(card) => setCardToChangeLabels(card)}
					onAttachments={(card) => setCardToAttach(card)}
					onSubTask={(card) => setSubTask(card)}
					onAddSubTask={(card) => setAddSubTask(card)}
					onViewAttachments={(card) => setCardToViewAttachment(card)}
					onDueDate={(card) => setCardToDueDate(card)}
					onChange={handleCardChange}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
					updateProject={updateProject}
					setIsUpdated={setIsUpdated}
					isUpdated={isUpdated}
				/>
			)}

			{cardToChangeMembers && (
				<KanbanEditMembersModal
					show={true}
					{...cardToChangeMembers}
					onHide={() => setCardToChangeMembers(null)}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
					setIsUpdated={setIsUpdated}
					isUpdated={isUpdated}
				/>
			)}

			{cardToChangeSubMembers && (
				<KanbanEditSubMembersModal
					show={true}
					{...cardToChangeSubMembers}
					onHide={() => setCardToChangeSubMembers(null)}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{cardToChangeLabels && (
				<KanbanEditLabelsModal
					show={true}
					{...cardToChangeLabels}
					onHide={() => setCardToChangeLabels(null)}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{cardToAttach && (
				<KanbanAttachmentModal
					show={true}
					{...cardToAttach}
					onHide={() => setCardToAttach(null)}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{subTask && (
				<KanbanSubTaskModal
					show={true}
					{...subTask}
					onHide={() => setSubTask(null)}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{addSubTask && (
				<KanbanAddSubTaskModal
					show={true}
					{...addSubTask}
					onHide={() => setAddSubTask(null)}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{cardToViewAttachment && (
				<KanbanViewAttachmentModal
					show={true}
					{...cardToViewAttachment}
					onHide={() => setCardToViewAttachment(null)}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{cardToDueDate && (
				<KanbanDueDateModal
					show={true}
					{...cardToDueDate}
					onHide={() => setCardToDueDate(null)}
					onSubmit={handleCardChange}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{cardToCopy && (
				<KanbanCopyModal
					show={true}
					{...cardToCopy}
					lists={kanbanLists}
					onHide={() => setCardToCopy(null)}
					onSubmit={handleCopyCard}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{cardToMove && (
				<KanbanMoveModal
					show={true}
					{...cardToMove}
					lists={kanbanLists}
					onHide={() => setCardToMove(null)}
					onSubmit={handleDragEnd}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{showCreateListModal && (
				<KanbanCreateModal
					type="list"
					modalTitle="Add a new list"
					show={showCreateListModal}
					onHide={toggleCreateListModal}
					onSubmit={handleCreateList}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{listToCopy && (
				<KanbanCopyModal
					show={true}
					type="list"
					{...listToCopy}
					onHide={() => setListToCopy(null)}
					onSubmit={handleCreateList}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			{listToMoveIndex !== null && (
				<KanbanMoveModal
					show={true}
					type="list"
					lists={kanbanLists}
					listIndex={listToMoveIndex}
					onHide={() => setListToMoveIndex(null)}
					onSubmit={handleMoveList}
					project={project}
					projectId={project?._id || projectId}
				/>
			)}

			<KanbanHeader
				onNewCard={toggleCreateCardModal}
				onArchive={handleArchiveCards}
				onDelete={handleCardsDelete}
				project={project}
				projectId={project?._id || projectId}
				showView={showView}
				setShowView={setShowView}
				showAdd={showAdd}
				setShowAdd={setShowAdd}
			/>

			<Container fluid className="kanban-container py-4 px-0">
				<Row className="d-flex flex-nowrap">
					<DragDropContext onDragEnd={handleDragEnd}>
						{kanbanLists?.map((list, ind) => {
							const { id: listId, tasks } = list;

							return (
								<Droppable
									index={ind}
									droppableId={`${listId}`}
									key={`kanban-list-${listId}`}
								>
									{(provided) => {
										const { innerRef: listRef, placeholder, droppableProps } = provided;

										return (
											<KanbanList
												{...list}
												listRef={listRef}
												extraProps={droppableProps}
												onCardAdd={() => toggleCreateCardModal({ listId })}
												onListCopy={() => setListToCopy(list)}
												onListMove={() => setListToMoveIndex(ind)}
												onListDelete={handleListDelete}
												onTitleChange={handleListTitleChange}
											>
												{tasks?.map((card, index) => {
													const { id: cardId } = card;

													return (
														<Draggable
															index={index}
															draggableId={`${cardId}`}
															key={`kanban-card-${cardId}`}
														>
															{(provided, snapshot) => {
																const {
																	innerRef: cardRef,
																	draggableProps,
																	dragHandleProps,
																} = provided;

																return (
																	<KanbanCard
																		{...card}
																		cardRef={cardRef}
																		style={getCardStyle(draggableProps.style, snapshot)}
																		extraProps={{ ...draggableProps, ...dragHandleProps }}
																		onDelete={() => handleCardsDelete([{ listId, cardId }])}
																		onClick={() => setCardToEdit({ listId, index, ...card })}
																		onEdit={() => setCardToEdit({ listId, index, ...card })}
																		onCopy={() => setCardToCopy({ listId, ...card })}
																		onMove={() => setCardToMove({ listId, index })}
																		onChangeMembers={() =>
																			setCardToChangeMembers({ listId, ...card })
																		}
																		onChangeSubMembers={() =>
																			setCardToChangeSubMembers({ listId, ...card })
																		}
																		onChangeLabels={() =>
																			setCardToChangeLabels({ listId, ...card })
																		}
																		onAttachments={() => setCardToAttach({ listId, ...card })}
																		onSubTask={() => setSubTask({ listId, ...card })}
																		onAddSubTask={() => setAddSubTask({ listId, ...card })}
																		onViewAttachments={() =>
																			setCardToViewAttachment({ listId, ...card })
																		}
																		onDueDate={() => setCardToDueDate({ listId, ...card })}
																		projectId={project?._id || projectId}
																		project={project}
																		setIsUpdated={setIsUpdated}
																		isUpdated={isUpdated}
																	/>
																);
															}}
														</Draggable>
													);
												})}

												{placeholder}
											</KanbanList>
										);
									}}
								</Droppable>
							);
						})}
					</DragDropContext>
				</Row>
			</Container>
		</>
	);
};

export default Kanban;
